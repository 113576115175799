<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        title="KEEP IN TOUCH WITH US"
        pace="6"
      >
        Feel free to send me message if you have any concern regarding SwaVan or you like to see any new features in SwaVan.
      </base-info-card>

      <base-business-contact dense />
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessInfo',

    props: { dark: Boolean },

    data: () => ({
      business: [
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '01 ‪(804) 552-1167<br>01 (703) 477 7793',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'vpathsala@gmail.com<br>biplav.nep@gmail.com',
        },
      ],
    }),
  }
</script>
