<template>
  <div>
    <form @submit.prevent="sendEmail">
      <base-info-card
        :title="title"
        :subtitle="subtitle"
        space="4"
        color="primary"
      />

      <base-text-field
        v-model="form.user_name"
        label="Name"
        name="user_name"
        required
      />

      <base-text-field
        v-model="form.user_email"
        label="Email"
        name="user_email"
        required
        type="email"
      />

      <base-text-field
        v-model="form.subject"
        label="Subject"
        name="subject"
        required
      />

      <base-textarea
        v-model="form.message"
        name="message"
        class="mb-6"
        label="Your Need & Description"
        required
      />

      <base-btn
        :color="!theme.isDark ? 'accent' : 'white'"
        outlined
        type="submit"
      >
        Send message
      </base-btn>
    </form>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      color="light"
    >
      {{ snackMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import emailjs from 'emailjs-com'
  export default {
    name: 'BaseContactForm',
    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'MESSAGE',
      },
    },
    data () {
      return {
        form: {
          user_name: '',
          user_email: '',
          subject: '',
          message: '',
        },
        snackbar: false,
        timeout: 3000,
        snackMessage: '',
      }
    },
    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],
    methods: {
      async sendEmail  (e) {
        try {
          const email = await emailjs.sendForm('service_fd49h8a', 'template_08mj75b', e.target, 'user_gAXqrW1EqrRg6xdrDNbzv')
          if (email.status === 200) {
            this.snackMessage = 'Email Successfully sent'
            this.form = { user_name: '', user_email: '', subject: '', message: '' }
          }
        } catch {
          this.snackMessage = 'Sorry we are unable to send email'
        }
        this.snackbar = true
      },
    },
  }
</script>
